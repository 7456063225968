.collected {
    border: 1px solid darken(#343a40, 5%) !important;
    background-color: darken(#343a40, 2%);
}

.uncollected {
    border: 1px solid rgba(0, 0, 0, 0.000) !important; // needed to make sure comparison screen still lines up
}

img.item {
    height: 2.25rem;
    width: 2.25rem;
}

.uncollected img.item {
    filter: grayscale(100%);
}

.badge-collected {
    color: white;
    background-color: #28a745;
    right: .25rem;
    top: .25rem;
}

.badge-uncollected {
    color: white;
    background-color: #b30000;
    z-index: 100;
    right: .25rem;
    top: .25rem;
}

.badge-0 {  // ALLIANCE
    color: white;
    background-color: #0078ff;
}

.badge-1 {  // HORDE
    color: white;
    background-color: #b30000;
}

.badge-un-1, .badge-un-2, .badge-un-4 { // UNOBTAINABLE, NEVER IMPLEMENTED, // BUGGED
    color: white;
    background-color: #b30000;
}

.badge-un-3 { // OBTAINABLE FOR LIMITED TIME
    color: white;
    background-color: #28a745;
}

.badge-quality-0 { // POOR
    color: white;
    background-color: #9d9d9d;
    right: .25rem;
    bottom: .25rem;
}

.badge-quality-1 { // COMMON
    color: #9d9d9d;
    background-color: #ffffff;
    right: .25rem;
    bottom: .25rem;
}

.badge-quality-2 { // UNCOMMON
    color: white;
    background-color: #28a745;
    right: .25rem;
    bottom: .25rem;
}

.badge-quality-3 { // RARE
    color: white;
    background-color: #0070dd;
    right: .25rem;
    bottom: .25rem;
}
.scroller {
    width: 100%;
    max-height: 50vh;
    overflow-y: auto;
    justify-content: flex-start;
}

.collection {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    //grid-auto-rows: 3.25rem;
    grid-gap: .5rem;
}

@media (min-width: 800px) {
    .collection-nosplit {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media (min-width: 1200px) {
    .collection-nosplit {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media (min-width: 1600px) {
    .collection-nosplit {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

@media (min-width: 2000px) {
    .collection-nosplit {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
}

@media (min-width: 2400px) {
    .collection-nosplit {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
}

@media (min-width: 2800px) {
    .collection-nosplit {
        grid-template-columns: repeat(7, minmax(0, 1fr));
    }
}

@media (min-width: 3200px) {
    .collection-nosplit {
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }
}
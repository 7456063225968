.icon-0 {  // ALLIANCE
    height: 1.25rem;
    //fill: #0078ff;
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .5));
}

.icon-1 {  // HORDE
    height: 1.25rem;
    //fill: #b30000;
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .5));
}

.tag-tooltip {
    max-width: 50vw !important;
}

.tag-tooltip-body {
    max-height: 50vh !important;
    overflow-y: auto;
    overflow-x: hidden;
}

.badge.badge-align, .badge-align .icon-0, .badge-align .icon-1 {
    width: 1.25rem;
    margin-right: .25rem;
}